var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"order_detail_page"},[_c('van-nav-bar',{attrs:{"title":"订单详情","fixed":true,"placeholder":true,"border":false,"left-arrow":true},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"order_detail_content"},[_c('van-cell-group',{attrs:{"inset":""}},[_c('van-cell',[_c('div',{staticClass:"flex_between"},[_c('div',[_c('div',{staticClass:"flex_align_c"},[_c('van-icon',{attrs:{"name":"label-o","color":"#1fa5ff","size":"22"}}),_c('span',{staticClass:"fs20 fwb ml5"},[_vm._v(_vm._s(_vm.orderDetail.OrderStatus))])],1)]),_c('div',{staticClass:"tr",on:{"click":function($event){_vm.showHotelPrice = true}}},[_c('div',{staticClass:"price"},[_vm._v(" ￥"),_c('span',{staticClass:"fs20"},[_vm._v(_vm._s(_vm.orderDetail.Price))])]),_c('div',{staticStyle:{"color":"#1fa5ff"}},[_vm._v(" 明细"),_c('van-icon',{attrs:{"name":"arrow","color":"#1fa5ff"}})],1)])]),_c('div',{staticClass:"hui_color"},[_c('span',[_vm._v("订单号  ")]),_c('span',[_vm._v(_vm._s(_vm.orderDetail.OrderNumber))])]),(_vm.orderDetail.CancelRule)?_c('div',{staticClass:"fs12 alike_style",class:[
            _vm.orderDetail.CancelRule == '不可退订'
              ? 'never_style'
              : 'xianshi_style',
          ]},[_vm._v(" "+_vm._s(_vm.orderDetail.CancelRule)+" ")]):_vm._e()])],1),_c('van-cell-group',{attrs:{"inset":""}},[_c('van-cell',[_c('div',{staticClass:"flex_around",staticStyle:{"color":"#1fa5ff"}},[_c('div',{on:{"click":_vm.callHotel}},[_c('van-icon',{attrs:{"name":"phone-o","color":"#1fa5ff"}}),_vm._v(" 联系酒店 ")],1),_c('div',{on:{"click":_vm.toHotelDetailPage}},[_c('van-icon',{attrs:{"name":"orders-o","color":"#1fa5ff"}}),_vm._v(" 酒店详情 ")],1)])]),_c('HotelOrderInfo',{staticClass:"hotel_info",attrs:{"hotelInfoData":_vm.orderDetail}})],1),_c('van-cell-group',{attrs:{"inset":""}},[_c('van-cell',[_c('div',{staticClass:"flex info_style"},[_c('div',{staticClass:"title"},[_vm._v("入住人")]),_c('div',_vm._l((_vm.orderDetail.Guests),function(guests,index){return _c('div',{key:index},[_c('div',[_vm._v(_vm._s(guests.TravelName))]),(_vm.orderDetail.Guests.length - index > 1)?_c('van-divider'):_vm._e()],1)}),0)])])],1),_c('van-cell-group',{attrs:{"inset":""}},[_c('van-cell',[_c('div',{staticClass:"flex info_style"},[_c('div',{staticClass:"title"},[_vm._v("联系人")]),_c('div',[_vm._v(_vm._s(_vm.orderDetail.ContactName))])]),_c('div',{staticClass:"flex info_style"},[_c('div',{staticClass:"title"},[_vm._v("联系人手机号")]),_c('div',[_vm._v(_vm._s(_vm.orderDetail.ContactNumber))])])])],1),_c('van-cell-group',{attrs:{"inset":""}},[_c('van-cell',[_c('div',{staticClass:"flex info_style"},[_c('div',{staticClass:"title"},[_vm._v("预订日期")]),_c('div',[_vm._v(_vm._s(_vm.orderDetail.CreateDate))])]),_c('div',{staticClass:"flex info_style"},[_c('div',{staticClass:"title"},[_vm._v("订单编号")]),_c('div',[_vm._v(_vm._s(_vm.orderDetail.OrderNumber))])])])],1)],1),(
      _vm.orderDetail.ApplyReturnBtn ||
      _vm.orderDetail.CancelBtn ||
      _vm.orderDetail.PayBtn
    )?_c('Footer',{staticClass:"footer_style"},[_c('div',[(_vm.orderDetail.ApplyReturnBtn || _vm.orderDetail.CancelBtn)?_c('van-button',{staticClass:"mb10",attrs:{"type":"danger","block":""},on:{"click":_vm.handleCancel}},[_vm._v(_vm._s((_vm.orderDetail.ApplyReturnBtn && "退订") || (_vm.orderDetail.CancelBtn && "取消订单")))]):_vm._e(),(_vm.orderDetail.PayBtn)?_c('van-button',{attrs:{"type":"primary","block":""},on:{"click":_vm.payOrder}},[_vm._v("支付")]):_vm._e()],1)]):_vm._e(),_c('van-popup',{staticClass:"hotel_price_detail",model:{value:(_vm.showHotelPrice),callback:function ($$v) {_vm.showHotelPrice=$$v},expression:"showHotelPrice"}},[_c('div',{staticClass:"color_f75d20 mb20"},[_vm._v("费用明细")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"flex_justify_b_align_c fs14 mb10"},[_c('div',[_vm._v("房费")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr50"},[_vm._v(" "+_vm._s(_vm.orderDetail.RoomCount)+"间"+_vm._s(_vm.ruzhuDateNum)+"晚 ")]),_c('div',[_vm._v("￥"+_vm._s(_vm.orderDetail.Price))])])]),_c('div',{staticClass:"room_info_price"},[_c('div',{staticClass:"title flex"},[_c('div',[_vm._v("日期")]),_c('div',[_vm._v("房间数")]),_c('div',[_vm._v("单间价格")])]),_vm._l((_vm.orderDetail.OrderPriceDetail),function(item,index){return _c('div',{key:index,staticClass:"info flex"},[_c('div',[_vm._v(_vm._s(item.PriceDate))]),_c('div',[_vm._v(_vm._s(_vm.orderDetail.RoomCount))]),_c('div',[_vm._v("￥"+_vm._s(item.Price))])])})],2)]),(_vm.orderDetail.ServicePrice)?_c('div',{staticClass:"color_f75d20 tr mb10"},[_vm._v(" 服务费￥"+_vm._s(_vm.orderDetail.ServicePrice)+" ")]):_vm._e(),_c('div',{staticClass:"color_f75d20 tr"},[_vm._v("总价￥"+_vm._s(_vm.orderDetail.Price))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }

import { Component, Vue, Watch } from "vue-property-decorator";
import {
  Cell,
  CellGroup,
  Divider,
  NavBar,
  Icon,
  Popup,
  Button,
  Dialog,
} from "vant";
import { fectHotelOrder, fectHotelOrderPay } from "@/api/hotel/index.api";
import HotelOrderInfo from "@/components/hotelOrderInfo/index.vue";
@Component({
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Divider.name]: Divider,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    HotelOrderInfo,
  },
})
export default class OrderDetail extends Vue {
  public OrderNumber = "";
  public orderDetail: any = {};
  public async getOrderDetail() {
    let {
      data: { ReturnJson },
    } = await fectHotelOrder({
      Action: "QueryOrderDetail",
      OrderNumber: this.OrderNumber,
    });
    this.$set(this, "orderDetail", JSON.parse(ReturnJson));
  }

  public callHotel() {
    location.href = `tel:${this.orderDetail.HotelPhone}`;
  }
  public toHotelDetailPage() {
    this.$router.push({
      path: "/hotelDetail",
      query: {
        HotelCode: this.orderDetail.HotelCode,
      },
    });
  }

  get ruzhuDateNum() {
    if (!this.orderDetail.CheckInDate || !this.orderDetail.CheckOutDate) return;
    let inDate = this.$moment(this.orderDetail.CheckInDate);
    let outDate = this.$moment(this.orderDetail.CheckOutDate);
    return outDate.diff(inDate, "days");
  }

  public showHotelPrice: boolean = false;

  public async handleCancel() {
    let res;
    if (this.orderDetail.CancelBtn) {
      await Dialog.confirm({
        title: "提示",
        message: "确认取消订单吗？",
      });
      res = await fectHotelOrder({
        Action: "CancelOrder",
        OrderNumber: this.orderDetail.OrderNumber,
      });
    } else {
      await Dialog.confirm({
        title: "提示",
        message: "是否退订？",
      });
      res = await fectHotelOrder({
        Action: "ReturnOrder",
        OrderNumber: this.orderDetail.OrderNumber,
      });
    }
    setTimeout(() => {
      Dialog.alert({
        title: this.orderDetail.CancelBtn ? "取消" : "退订" + "成功",
        message: JSON.parse(res.data.ReturnJson),
      });
      this.getOrderDetail();
    }, 1000);
  }
  public async payOrder() {
    let res = await fectHotelOrderPay({
      OrderNumber: this.orderDetail.OrderNumber,
    });
    let payData = JSON.parse(res.data.ReturnJson);

    let that = this;
    // eslint-disable-line
    window.WeixinJSBridge.invoke(
      "getBrandWCPayRequest",
      {
        appId: payData.appId, //公众号ID，由商户传入
        timeStamp: payData.timeStamp, //时间戳，自1970年以来的秒数
        nonceStr: payData.nonceStr, //随机串
        package: payData.package,
        signType: payData.signType, //微信签名方式：
        paySign: payData.paySign, //微信签名
      },
      async (res) => {
        if (res.errMsg) {
          await Dialog.alert({
            title: "提示",
            message: res.errMsg,
          });
        }
        that.getOrderDetail();
      }
    );
  }

  created() {
    if (this.$route.query.OrderNumber) {
      this.OrderNumber = this.$route.query.OrderNumber + "";
      this.getOrderDetail();
    }
  }
}
